import { useEffect, useState } from 'react';
import {
    BlockingLoader,
    Button,
    ButtonGroup,
    ButtonVariant,
    CauseOfLoss,
    ClaimRequestDetails,
    ClaimRequestStatus,
    ClaimRequestType,
    ClinicalHistory,
    ClinicalHistoryWrapper,
    CloseIncidentManuallyConfirmationDialog,
    CreationNote,
    DeleteIncidentManuallyConfirmationDialog,
    DuplicateClaimConfirmationDialog,
    Dialog,
    FraudAlert,
    getDialogDetailsFromError,
    MarkIncidentMissingInfoStatusModal,
    Modal,
    Note,
    NoteScope,
    NoteTypeValue,
    ParentCard,
    PetProfile,
    Policy,
    PolicyCard,
    RequestDetailHeader,
    SupportingDocumentDeletionReason,
    useDetectClickOutside,
    useDialog,
    useNotes,
    User,
    MissingInfoCategoryWithDetails,
} from '@barkibu/noma-commons';
import { IncidentDetails as IncidentDetailsDomain } from '../../../domain/entities/IncidentDetails';
import { SupportingDocument } from '../../../domain/entities/supporting-document';
import { useModuleTranslation } from '../../../utils/useModuleTranslation';
import IncidentInformation from '../../components/organisms/incident-information/IncidentInformation';
import SupportingDocuments from '../../components/organisms/supporting-documents/SupportingDocuments';
import './incident-details.scss';

export interface IncidentDetailsProps {
    claimRequestDetails?: ClaimRequestDetails;
    incidentDetails?: IncidentDetailsDomain;
    clinicalHistory?: ClinicalHistory;
    userReservation?: User;
    notes: Note[];
    fraudAlerts?: FraudAlert[];
    onContinueWithIncident: (missingInfoCategoriesWithDetails: MissingInfoCategoryWithDetails[]) => Promise<void>;
    onCreateDocument: (document: SupportingDocument) => void;
    onUpdateDocument: (document: SupportingDocument) => void;
    onDeleteDocument: (
        key: string,
        document: SupportingDocument,
        deletionReason: SupportingDocumentDeletionReason
    ) => void;
    onUpdateDocumentIsNewlyAddedByParent: (key: string, document: SupportingDocument) => void;
    onUpdateCauseOfLoss: (causeOfLoss: CauseOfLoss) => void;
    onUpdateDateOfLoss: (dateOfLoss: Date) => void;
    onUpdateEmail: (email: string) => void;
    onUpdateIban: (iban: string) => void;
    onAddNote: (note: CreationNote) => void;
    onDeleteNote: (note: Note) => void;
    onChangePolicy: (policyIdentifier: string) => void;
    onMarkAsHoldIncident: () => Promise<void>;
    onDuplicateClaimRequest: () => Promise<void>;
    onCloseIncident: (closeReason?: string, explanationForParent?: string) => void;
    onDeleteClaimRequest: () => void;
    onStealReservation?: () => void;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    contractIdsActiveOnClaimRequest: string[];
}

const IncidentDetails = ({
    claimRequestDetails,
    incidentDetails,
    clinicalHistory,
    userReservation,
    notes,
    fraudAlerts,
    onContinueWithIncident,
    loading = false,
    setLoading,
    onCreateDocument,
    onUpdateDocument,
    onDeleteDocument,
    onUpdateDocumentIsNewlyAddedByParent,
    onUpdateCauseOfLoss,
    onUpdateDateOfLoss,
    onUpdateEmail,
    onUpdateIban,
    onAddNote,
    onDeleteNote,
    onChangePolicy,
    onMarkAsHoldIncident,
    onDuplicateClaimRequest,
    onCloseIncident,
    onDeleteClaimRequest,
    onStealReservation,
    contractIdsActiveOnClaimRequest,
}: IncidentDetailsProps) => {
    const { t } = useModuleTranslation();
    const { dialogOptions, openDialog, closeDialog } = useDialog();
    const [showMarkIncidentMissingInfoStatusModal, setShowMarkIncidentMissingInfoStatusModal] = useState(false);
    const [showDuplicateClaimConfirmationDialog, setShowDuplicateClaimConfirmationDialog] = useState(false);

    const {
        notes: policyNotes,
        createNote: addPolicyNote,
        deleteNote: deletePolicyNote,
    } = useNotes(
        NoteScope.POLICY,
        claimRequestDetails?.claimRequestKey ?? undefined,
        claimRequestDetails?.policyIdentifier ?? undefined
    );
    const {
        notes: petParentNotes,
        createNote: addPetParentNote,
        deleteNote: deletePetParentNote,
    } = useNotes(
        NoteScope.PET_PARENT,
        claimRequestDetails?.claimRequestKey ?? undefined,
        claimRequestDetails?.policyIdentifier ?? undefined
    );
    const {
        notes: petNotes,
        createNote: addPetNote,
        deleteNote: deletePetNote,
    } = useNotes(
        NoteScope.PET,
        claimRequestDetails?.claimRequestKey ?? undefined,
        claimRequestDetails?.policyIdentifier ?? undefined
    );
    const handleAddNote = (note: CreationNote) => {
        switch (note.scope) {
            case NoteScope.POLICY:
                addPolicyNote(note);
                break;
            case NoteScope.PET:
                addPetNote(note);
                break;
            case NoteScope.PET_PARENT:
                addPetParentNote(note);
                break;
        }
    };

    const handleDeleteNote = (note: Note) => {
        switch (note.scope) {
            case NoteScope.POLICY:
                deletePolicyNote(note.key);
                break;
            case NoteScope.PET:
                deletePetNote(note.key);
                break;
            case NoteScope.PET_PARENT:
                deletePetParentNote(note.key);
                break;
        }
    };

    const [showCloseIncidentModal, setShowCloseIncidentModal] = useState(false);
    const [showDeleteIncidentModal, setShowDeleteIncidentModal] = useState(false);
    const [showOtherActionsWrapper, setShowOtherActionsWrapper] = useState(false);
    const [ibanParentCardValid, setIbanParentCardValid] = useState(false);
    const [emailParentCardValid, setEmailParentCardValid] = useState(false);

    const ref = useDetectClickOutside({
        onTriggered: () => {
            if (showOtherActionsWrapper) {
                setShowOtherActionsWrapper(false);
            }
        },
    });

    const handleContinueWithIncident = () => {
        if (!emailParentCardValid) {
            openDialog(t('incident.error.email_null'));
            return;
        }
        if (!ibanParentCardValid && !claimRequestDetails?.isPrepaid()) {
            openDialog(t('incident.error.iban'));
            return;
        }
        if (claimRequestDetails?.type != ClaimRequestType.PREVENTION && claimRequestDetails?.dateOfLoss == null) {
            openDialog(t('incident.error.date_of_loss'));
            return;
        }
        onContinueWithIncident([]);
    };

    const handleValidationIban = (isValidIban: boolean) => {
        setIbanParentCardValid(isValidIban);
    };

    const handleValidationEmail = (isValidEmail: boolean) => {
        setEmailParentCardValid(isValidEmail);
    };
    const handleUpdateIncidentStatusModalClose = () => setShowMarkIncidentMissingInfoStatusModal(false);

    const handleMarkIncidentMissingInfoStatusModalSave = async (incidentStatus: MissingInfoCategoryWithDetails[]) => {
        setShowMarkIncidentMissingInfoStatusModal(false);
        if (claimRequestDetails?.email == null) {
            openDialog(t('incident.error.email_null'));
            return;
        }
        try {
            await onContinueWithIncident(incidentStatus);
        } catch (e) {
            openDialog(t('incident.error.create_claim_from_incident'), getDialogDetailsFromError(e));
        }
    };

    const handleCloseIncidentDialogClose = () => setShowCloseIncidentModal(false);

    const handleCloseIncidentDialog = async (closeReason?: string, explanationForParent?: string) => {
        setShowCloseIncidentModal(false);
        try {
            await onCloseIncident(closeReason, explanationForParent);
        } catch (e) {
            openDialog(t('incident.error.close_incident'), getDialogDetailsFromError(e));
        }
    };

    const handleDeleteIncidentDialogClose = () => setShowDeleteIncidentModal(false);

    const handleDeleteIncidentDialog = async () => {
        setShowDeleteIncidentModal(false);
        try {
            await onDeleteClaimRequest();
        } catch (e) {
            openDialog(t('incident.error.delete_incident'), getDialogDetailsFromError(e));
        }
    };

    const handleUpdateEmail = (email: string) => {
        if (claimRequestDetails?.email != email) {
            onUpdateEmail(email);
        }
    };
    const handleUpdateIban = (iban: string) => {
        if (claimRequestDetails?.iban != iban) {
            onUpdateIban(iban);
        }
    };

    const handleCreateSupportingDocument = (document: SupportingDocument) => {
        onCreateDocument(document);
    };
    const handleUpdateSupportingDocument = (document: SupportingDocument) => {
        onUpdateDocument(document);
    };

    const handleDeleteSupportingDocument = (
        document: SupportingDocument,
        deletionReason: SupportingDocumentDeletionReason
    ) => {
        onDeleteDocument(incidentDetails?.incidentKey!, document, deletionReason);
    };
    const handleUpdateSupportingDocumentIsNewlyAddedByParent = (document: SupportingDocument) => {
        onUpdateDocumentIsNewlyAddedByParent(incidentDetails?.incidentKey!, document);
    };

    const handleChangeIncidentPolicy = (policy: Policy) => {
        onChangePolicy(policy.identifier);
    };

    const [causeOfLoss, setCauseOfLoss] = useState<string | null | undefined>(claimRequestDetails?.causeOfLoss);

    useEffect(() => {
        setCauseOfLoss(claimRequestDetails?.causeOfLoss);
    }, [claimRequestDetails?.causeOfLoss]);

    const handleCauseOfLossChange = (causeOfLoss: string | null) => {
        setCauseOfLoss(causeOfLoss);
        if (causeOfLoss != null) {
            onUpdateCauseOfLoss(CauseOfLoss[causeOfLoss as keyof typeof CauseOfLoss]);
        }
    };

    const shouldShowButtons = incidentDetails?.status!.description != ClaimRequestStatus.CLOSED;

    const handleOpenMarkAsMissingInfoModal = () => {
        setShowOtherActionsWrapper(false);
        setShowMarkIncidentMissingInfoStatusModal(true);
    };

    return (
        <>
            {!loading && (
                <section className="incident-details incident-details--container">
                    <div className="incident-details--banner">
                        <PetProfile
                            name={incidentDetails?.petName}
                            birth={incidentDetails?.petBirth}
                            breed={incidentDetails?.petBreed}
                            gender={incidentDetails?.petGender}
                            chip={incidentDetails?.petChip}
                            photo={incidentDetails?.petPhoto}
                            totalClaimRequestCount={incidentDetails?.policy?.claimRequestStats?.totalClaimRequestCount}
                            openClaimRequestCount={incidentDetails?.policy?.claimRequestStats?.openClaimRequestCount}
                            tags={petNotes
                                .filter((note) => note.type == NoteTypeValue.TAG)
                                .map((petNote) => petNote.description)}
                        />
                        <div className="incident-details--expanded-column">
                            {incidentDetails?.petPolicies && (
                                <PolicyCard
                                    setLoading={setLoading}
                                    fraudAlerts={fraudAlerts}
                                    policyIdentifier={claimRequestDetails?.policyIdentifier!}
                                    countryCode={incidentDetails?.countryCode}
                                    policies={incidentDetails?.petPolicies}
                                    onPolicyClick={handleChangeIncidentPolicy}
                                    petName={incidentDetails?.petName ?? ''}
                                    tags={policyNotes
                                        .filter((note) => note.type == NoteTypeValue.TAG)
                                        .map((policyNote) => policyNote.description)}
                                    contractIdsActiveOnClaimRequest={contractIdsActiveOnClaimRequest}
                                />
                            )}
                            <ParentCard
                                email={claimRequestDetails?.email}
                                iban={claimRequestDetails?.iban}
                                readOnly={incidentDetails?.readOnly}
                                onUpdateEmail={handleUpdateEmail}
                                onUpdateIban={handleUpdateIban}
                                onValidationIban={handleValidationIban}
                                onValidationEmail={handleValidationEmail}
                                hideIban={claimRequestDetails?.isPrepaid()}
                                tags={petParentNotes
                                    .filter((note) => note.type == NoteTypeValue.TAG)
                                    .map((petParentNote) => petParentNote.description)}
                            />
                        </div>
                    </div>
                    <div className="incident-details--body">
                        {claimRequestDetails?.claimRequestKey && (
                            <RequestDetailHeader
                                date={incidentDetails?.date}
                                userReservation={userReservation}
                                seePreviousUrl={`/dataentry/policy/${claimRequestDetails?.policyIdentifier!}/incidents`}
                                onStealReservation={onStealReservation}
                                policyNotes={policyNotes}
                                petNotes={petNotes}
                                petParentNotes={petParentNotes}
                                onAddNote={handleAddNote}
                                onDeleteNote={handleDeleteNote}
                                prepaidExpenseIds={claimRequestDetails.prepaidExpenseIds}
                            />
                        )}
                        <div className="incident-details--body-content">
                            <IncidentInformation
                                claimRequestKey={claimRequestDetails?.claimRequestKey}
                                status={incidentDetails?.status}
                                readOnly={incidentDetails?.readOnly}
                                requestedDate={incidentDetails?.date!}
                                dateOfLoss={claimRequestDetails?.dateOfLoss}
                                causeOfLoss={CauseOfLoss[causeOfLoss as keyof typeof CauseOfLoss]}
                                claimRequestType={claimRequestDetails?.type}
                                notes={notes}
                                onUpdateDateOfLoss={onUpdateDateOfLoss}
                                onUpdateCauseOfLoss={handleCauseOfLossChange}
                                onAddNote={onAddNote}
                                onDeleteNote={onDeleteNote}
                                visitReason={incidentDetails?.description}
                            />
                            <div className="incident-details--body-content--right">
                                {clinicalHistory && claimRequestDetails?.policyIdentifier && (
                                    <ClinicalHistoryWrapper
                                        clinicalHistory={clinicalHistory}
                                        policyIdentifier={claimRequestDetails?.policyIdentifier}
                                    />
                                )}
                                <SupportingDocuments
                                    title={t('incident.documents')}
                                    documents={incidentDetails?.supportingDocuments || []}
                                    onCreateDocument={handleCreateSupportingDocument}
                                    onUpdateDocument={handleUpdateSupportingDocument}
                                    onDeleteDocument={handleDeleteSupportingDocument}
                                    onUpdateDocumentIsNewlyAddedByParent={
                                        handleUpdateSupportingDocumentIsNewlyAddedByParent
                                    }
                                    onMarkAsMissingInfo={handleOpenMarkAsMissingInfoModal}
                                    allowEdit={!incidentDetails?.readOnly}
                                />
                            </div>
                        </div>
                    </div>
                    {shouldShowButtons && (
                        <div className="incident-details--footer">
                            <Button
                                text={t('incident.continue')}
                                onClick={handleContinueWithIncident}
                                disabled={loading}
                            />

                            <Button
                                text={t('incident.other_actions')}
                                onClick={(e) => {
                                    setShowOtherActionsWrapper(true);
                                    e.stopPropagation();
                                }}
                                variantName={ButtonVariant.SECONDARY}
                                disabled={loading}
                            />
                        </div>
                    )}
                </section>
            )}
            <Modal isOpen={showOtherActionsWrapper}>
                <ButtonGroup variantName={''} ref={ref}>
                    <Button
                        text={t('incident.mark_as_missing_info')}
                        onClick={handleOpenMarkAsMissingInfoModal}
                        variantName={ButtonVariant.SECONDARY}
                        disabled={loading}
                    />
                    {incidentDetails?.status!.description != ClaimRequestStatus.ON_HOLD && (
                        <Button
                            text={t('incident.mark_as_on_hold')}
                            onClick={() => {
                                setShowOtherActionsWrapper(false);
                                onMarkAsHoldIncident();
                            }}
                            variantName={ButtonVariant.SECONDARY}
                            disabled={loading}
                        />
                    )}
                    <Button
                        text={t('incident.duplicate_claim')}
                        onClick={() => {
                            setShowOtherActionsWrapper(false);
                            setShowDuplicateClaimConfirmationDialog(true);
                        }}
                        variantName={ButtonVariant.SECONDARY}
                        disabled={loading}
                    />

                    <Button
                        text={t('incident.close_incident')}
                        onClick={() => {
                            setShowOtherActionsWrapper(false);
                            setShowCloseIncidentModal(true);
                        }}
                        variantName={ButtonVariant.DANGER}
                        disabled={loading}
                    />
                    <Button
                        text={t('incident.delete_incident')}
                        onClick={() => {
                            setShowOtherActionsWrapper(false);
                            setShowDeleteIncidentModal(true);
                        }}
                        variantName={ButtonVariant.DANGER}
                        disabled={loading}
                    />
                </ButtonGroup>
            </Modal>
            {showCloseIncidentModal && incidentDetails && (
                <CloseIncidentManuallyConfirmationDialog
                    isOpen={showCloseIncidentModal}
                    onCloseClaim={handleCloseIncidentDialog}
                    onCancel={handleCloseIncidentDialogClose}
                    countryCode={incidentDetails.countryCode!}
                />
            )}
            <DeleteIncidentManuallyConfirmationDialog
                isOpen={showDeleteIncidentModal}
                onRemove={handleDeleteIncidentDialog}
                onCancel={handleDeleteIncidentDialogClose}
            />
            <DuplicateClaimConfirmationDialog
                isOpen={showDuplicateClaimConfirmationDialog}
                onSave={() => {
                    setShowDuplicateClaimConfirmationDialog(false);
                    onDuplicateClaimRequest();
                }}
                onCancel={() => setShowDuplicateClaimConfirmationDialog(false)}
            />

            <MarkIncidentMissingInfoStatusModal
                isOpen={showMarkIncidentMissingInfoStatusModal}
                onClose={handleUpdateIncidentStatusModalClose}
                onSave={handleMarkIncidentMissingInfoStatusModalSave}
                countryCode={incidentDetails?.countryCode}
                petName={incidentDetails?.petName}
                claimRequestType={claimRequestDetails?.type}
                claimRequestKey={claimRequestDetails?.claimRequestKey}
            />
            <Dialog
                isOpen={dialogOptions.isOpen}
                text={dialogOptions.text}
                details={dialogOptions.details}
                onClose={() => closeDialog()}
                actions={[
                    {
                        text: t('modal.close', { ns: 'commons' }),
                        variantName: ButtonVariant.PRIMARY,
                        onClick: () => closeDialog(),
                    },
                ]}
            />
            {loading && <BlockingLoader />}
        </>
    );
};

export { IncidentDetails };
