import { ClaimRequestStatus, MissingInfoCategoryDetail, MissingInfoCategoryWithDetails } from '../../../domain/values';
import { ClaimRequestStatusNomaRepository } from '../../../infraestructure';
import { MissingInfoCategoryWithDetailsDto } from '../../../infraestructure/repositories/claimRequestStatus/MissingInfoCategoryWithDetailsDto';
import { TemplateValueDto } from '../../../infraestructure/repositories/claimRequestStatus/TemplateValueDto';

const claimRequestNomaRepository = new ClaimRequestStatusNomaRepository();

export const useClaimRequestStatus = (claimRequestKey: string) => {
    const markStatusAsOnHold = async () => {
        await claimRequestNomaRepository.markStatusAsOnHold(claimRequestKey);
    };
    const markStatusAsMissingInfo = async (categoriesWithDetails: MissingInfoCategoryWithDetails[]) => {
        await claimRequestNomaRepository.markStatusAsMissingInfo(claimRequestKey, {
            documents: categoriesWithDetails.map((el) => {
                return toMissingInfoCategoryWithDetailsDto({
                    ...el,
                    values: el.values.filter((detail) => detail.value.trim() !== ''),
                });
            }),
        });
    };

    const markStatusAsManuallyClosed = async (closeReason?: string, explanationForParent?: string) => {
        await claimRequestNomaRepository.markStatusAsManuallyClosed(claimRequestKey, closeReason, explanationForParent);
    };

    const markStatusAsCreated = async () => {
        await claimRequestNomaRepository.markStatusAsOnCreated(claimRequestKey);
    };

    const hasStatusReservable = (claimRequestStatus: ClaimRequestStatus) => {
        switch (claimRequestStatus) {
            case ClaimRequestStatus.CREATED:
            case ClaimRequestStatus.MISSING_INFO:
            case ClaimRequestStatus.ON_HOLD:
            case ClaimRequestStatus.READY_FOR_VET_ANALYSIS:
            case ClaimRequestStatus.PENDING_DATA_REVIEW:
            case ClaimRequestStatus.PENDING_MUNICH_RE:
                return true;
            default:
                return false;
        }
    };

    const toMissingInfoCategoryWithDetailsDto = (
        domain: MissingInfoCategoryWithDetails
    ): MissingInfoCategoryWithDetailsDto => {
        return {
            category: domain.category,
            values: domain.values.map((it) => toTemplateValueDto(it)),
        };
    };

    const toTemplateValueDto = (domain: MissingInfoCategoryDetail): TemplateValueDto => {
        return {
            label: domain.label,
            template: domain.value,
            missingInformationRequestedType: domain.missingInformationRequestedType,
        };
    };

    return {
        markStatusAsOnHold,
        markStatusAsMissingInfo,
        markStatusAsManuallyClosed,
        markStatusAsCreated,
        hasStatusReservable,
    };
};
